import Moment from 'moment'

export const queryHandler = ( searchParams, page, per_page ) => {
  let tmpFilter = ''
  searchParams.map((item, itemIndex) => {
    if ( item.param){
      if (itemIndex === 2 ){
        tmpFilter += `"${ item.value }": ${ paramHandler( item ) },`
      } else if (itemIndex === 0 ){
        tmpFilter += `"${ item.value }": ${ dateParamHandler( searchParams ) },`
      }
    }
  })

  return btoa(`{ "page": ${ page } ${ per_page ? `,"per_page": ${ per_page }` : '' } ${ tmpFilter.length > 0 ? `, "filter":{ ${ tmpFilter.slice(0, -1) } }` : '' }}` )
}

const paramHandler = item => {
  if ( typeof( item.param ) === 'number' || typeof( item.param ) === 'boolean' ){
    return item.param
  }

  if ( item.exact ){
    return `{"$like": "${ item.param }" }`
  }

  return `{"$like": "%${ item.param }%" }`
}

const dateParamHandler = (searchParams) => {
  return `{"$gt": "${Moment( searchParams[0].param ).format( 'YYYY-MM-DD' ) + 'T00:00:00.00000Z'}", "$lte": "${Moment( searchParams[1].param ).format( 'YYYY-MM-DD' ) + 'T23:59:59.00000Z'}"}`
}