import { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get } from 'utils/axios'
import { requestError } from 'utils/requestHandler'

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      companies: [],
      userCompany: null
    }

    onChangeCompanyHOC = (key, val) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getCompanies = () => Get(
      `/companies`,
      this.getCompanysSuccess,
      this.getCompanysError,
      this.load
    )
    getCompanysSuccess = payload => this.setState({ companies: _.map(payload.data, child => ({
      ... child,
      value: child.id,
      label: child.name
    })) })
    getCompanysError = error => requestError(error)

    getUserCompany = id => Get(
      `/users/${id}/company`,
      this.getUserCompanySuccess,
      this.getUserCompanyError,
      this.load
    )
    getUserCompanySuccess = payload => this.setState({ userCompany: payload })
    getUserCompanyError = error => requestError(error)

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          companies={this.state.companies}
          onLoadCompanies={this.state.loading}
          userCompany={this.state.userCompany}
          getUserCompany={this.getUserCompany}
          getCompanies={this.getCompanies}
          onChangeCompanyHOC={this.onChangeCompanyHOC}
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect(mapStateToProps)(WithHOC)
}

export default HOC