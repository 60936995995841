import TransComponent from 'components/Trans'
import _ from 'lodash'
import { Component } from 'react'
import Moment from 'moment'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { compose } from 'redux'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SearchForm from './components/searchForm'
import BKPTable from 'components/Table'
import PageTitle from 'components/Title/PageTitle'
import { getItem } from 'utils/tokenStore'

import CompanyHOC from './actions/company'
import UsersHOC from './actions/index'

class Users extends Component {

  componentDidMount = () => {
    this.props.getCompanies()

    if( this.props.data.ProfileReducer.profile.role_id === 4 ) {
      this.props.getUserCompany(this.props.data.ProfileReducer.profile.id)
    }


    getItem('BPK_USER_TYPE') !== 'non-case' &&
      this.props.history.push('/dashboard/case-mngmt')
  }

  componentDidUpdate = pp => {
    let tempIndex = _.findIndex(this.props.searchParams, { value: 'company_id' })
    let tempSearchParams = _.cloneDeep(this.props.searchParams)

    if (pp.companies !== this.props.companies && this.props.companies?.[0]) {
      if (tempIndex > -1) {
        tempSearchParams[tempIndex]['options'] = this.props.companies
      }
      this.props.onChangeReportHOC('searchParams', tempSearchParams)
    }

    if(pp.userCompany !== this.props.userCompany && this.props.userCompany) {
      if (tempIndex > -1) {
        tempSearchParams[tempIndex]['param'] = this.props.userCompany.company_id
      }
      this.props.onChangeReportHOC('searchParams', tempSearchParams)
    } 
  }

  render = () => {

    return (
      <>
        <PageTitle
          heading={<TransComponent i18nKey={`case_report.title.main`} />}
          subheading={<TransComponent i18nKey={`case_report.desc.main`} />}
          icon="pe-7s-folder icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <SearchForm
                per_page={10}
                searchParams={this.props.searchParams}
                onChangeHOCState={this.props.onChangeReportHOC}
                getListAPI={val => this.props.getReport(val)}
                downloadReport={this.props.downloadReport}
                renderCustomButton={val => {
                  return (
                    <Button
                      color="primary"
                      disabled={this.props.searchParams?.[2] ? !this.props.searchParams?.[2]?.param : false}
                      style={{ marginLeft: '10px' }}
                      onClick={() => this.props.downloadReport()}>
                      {<TransComponent i18nKey="general.download-pdf" />}
                    </Button>
                  )
                }}
              />
              <Card className="main-card mb-3">
                <CardBody>
                  <BKPTable
                    pageSize={10}
                    data={this.props.selectedReport}
                    columns={[
                      {
                        Header: <TransComponent i18nKey={`general.date`} />,
                        accessor: 'created_at',
                        Cell: (row) => (
                          <>
                            <span>{Moment(row.original.created_at).format('DD.MM.YYYY')}</span>
                          </>
                        )
                      },
                      {
                        Header: <TransComponent i18nKey={`login.fields.case`} />,
                        accessor: 'uid'
                      },
                      {
                        Header: <TransComponent i18nKey={`general.department`} />,
                        accessor: 'company_department'
                      },
                      {
                        Header: <TransComponent i18nKey={`general.city`} />,
                        accessor: 'city'
                      },
                      {
                        Header: <TransComponent i18nKey={`general.country`} />,
                        accessor: 'country'
                      },
                      {
                        Header: <TransComponent i18nKey={`general.status`} />,
                        accessor: 'tempStatus',
                        Cell: (row) => (
                          <span>
                            <TransComponent i18nKey={`lawyer_case.tabs.${row.original.tempStatus}`}/>
                          </span>
                        )
                      }
                    ]}
                    showPagination={true} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        {(this.props.onLoadReport) && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  CompanyHOC,
  UsersHOC
)(Users)