import { Component } from "react";
import { connect } from "react-redux";
import _ from 'lodash'

import { Get, GetFile } from "utils/axios";
import Moment from 'moment'
import { requestError } from "utils/requestHandler";
import { getItem } from 'utils/tokenStore'
import { queryHandler } from '../queryHandler'

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			selectedReport: [],
			searchParams: [
				{
					transKey: 'general.from',
					value: 'created_at',
					type: 'date',
					col: 6,
					param: new Date(new Date().setMonth(new Date().getMonth() - 1))
				},
				{
					transKey: 'general.to',
					value: 'created_at',
					type: 'date',
					col: 6,
					param: new Date()
				},
				{
					transKey: 'general.company',
					value: 'company_id',
					type: 'select',
					className: this.props.data.ProfileReducer.profile.role_id !== 4 ? '' : 'd-none',
					col: 6,
					param: null,
					options: []
				},
			],
			query: null
		};

		onChangeReportHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getReport = () => {
			let temp = queryHandler(this.state.searchParams, 0, 1000)

			Get(
				`/cases?query=${temp}`,
				this.getReportSuccess,
				this.getReportError,
				this.load
			)
		}
		getReportSuccess = payload => {
			let temp = _.map(payload, item => {
				let tempChild = _.cloneDeep(item)
				if (item.is_closed) {
					tempChild.tempStatus = 'case_closed'
				}

				if (item.is_urgent && !item.is_closed) {
					tempChild.tempStatus = 'urgent'
				}

				if (item.status === 'NEW' && !item.is_closed) {
					tempChild.tempStatus = 'unseen'
				}
				if (item.status === 'VIEWED' && !item.is_closed) {
					tempChild.tempStatus = 'progress'
				}
				if (item.status === 'REPLIED' && !item.is_closed) {
					tempChild.tempStatus = 'done'
				}

				return tempChild
			})

			this.setState({ selectedReport: temp })
		}
		getReportError = error => requestError(error)

		downloadReport = () => {
			let tempCompany = _.find(this.props.companies, child => child.id === this.state.searchParams[2].param)
			let tempName = tempCompany?.name?.replace(/ /g, '_') ?? '';

			GetFile(
				`/cases/${this.state.searchParams[2].param}/report/${Moment(this.state.searchParams[0].param).format('YYYY-MM-DD')}/${Moment(this.state.searchParams[1].param).format('YYYY-MM-DD')}`,
				`${tempName}_${Moment(this.state.searchParams[0].param).format('YYYY-MM-DD')}_${Moment(this.state.searchParams[1].param).format('YYYY-MM-DD')}`,
				this.downloadReportSuccess,
				this.downloadReportError,
				this.load
			)
		}
		downloadReportSuccess = () => { }
		downloadReportError = error => requestError(error)

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					onLoadReport={this.state.loading}
					getReport={this.getReport}
					downloadReport={this.downloadReport}
					onChangeReportHOC={this.onChangeReportHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
