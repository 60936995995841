import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  Row
} from 'reactstrap'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import TransComponent from 'components/Trans'

import { queryHandler } from 'utils/queryHandler'

const SearchForm = ({
  per_page,
  searchParams,
  onChangeHOCState,
  getListAPI,
  renderCustomButton
}) => {
  return (
    <Card className="main-card mb-3">
      <CardHeader style={{ textTransform: 'capital' }}>
        <TransComponent i18nKey="general.search" />
      </CardHeader>
      <CardBody>
        <Form onSubmit={e => e.preventDefault()}>
          <Row>
            {
              searchParams.map((item, index) => {
                return (
                  <Col key={index} md={item.col || 12} xs={12}>
                    <FormGroup key={item.value}>
                      <Label>
                        {
                          item.transKey && (
                            <TransComponent i18nKey={item.transKey} />
                          )
                        }
                        {
                          !item.transKey && (
                            `${item.label}`
                          )
                        }
                      </Label>
                      {
                        item.type === 'select' && (
                          <Input
                            type={'select'}
                            value={item.param}
                            onChange={e => {
                              let tmp = _.cloneDeep(searchParams)

                              let tempVal = parseInt(e.target.value)

                              if (item.valueType === 'string') {
                                tempVal = e.target.value
                              }
                              if (item.valueType === 'boolean') {
                                tempVal = e.target.value === 'true'
                              }

                              tmp[index]['param'] = tempVal
                              onChangeHOCState('searchParams', tmp)
                            }}>
                            <option></option>
                            {item.options.map(data => (
                              <option key={data.id} value={data.id}>{data.name}</option>
                            ))}
                          </Input>
                        )
                      }
                      {
                        item.type === 'text' && (
                          <Input
                            type={'text'}
                            value={item.param}
                            onChange={e => {
                              let tmp = _.cloneDeep(searchParams)
                              tmp[index]['param'] = e.target.value
                              onChangeHOCState('searchParams', tmp)
                            }} />
                        )
                      }
                      {
                        item.type === 'date' && (
                          <DatePicker
                            className={'w-100 form-control'}
                            showTimeSelect
                            dateFormat="dd-MM-yyyy h:mm aa"
                            selected={item.param}
                            onChange={val => {
                              let tmp = _.cloneDeep(searchParams)
                              tmp[index]['param'] = val
                              onChangeHOCState('searchParams', tmp)
                            }}
                          />
                        )
                      }
                    </FormGroup>
                  </Col>
                )
              })
            }
          </Row>
          <div className="d-flex">
            <Button
              color="primary"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                let searchQuery = queryHandler(searchParams, 0, per_page)
                getListAPI(searchQuery)
              }}>{<TransComponent i18nKey="general.search" />}</Button>
            {
              renderCustomButton &&
              renderCustomButton(queryHandler(searchParams, 0, per_page))
            }
            <Button
              color="danger"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                let tmp = []
                let tmpSearchParams = _.cloneDeep(searchParams)
                tmpSearchParams.map(item => {
                  tmp.push({
                    ...item,
                    param: ''
                  })
                })
                onChangeHOCState('searchParams', tmp)
                let searchQuery = queryHandler([], 0, per_page)
                getListAPI(searchQuery)
              }}>{<TransComponent i18nKey="general.reset" />}</Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default SearchForm