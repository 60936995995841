import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import DocumentMeta from 'react-document-meta';

import TemplateContainerMain from 'components/Template'
import TemplateHeader from 'components/Header'
import TemplateSidemenu from 'components/Menu/Sidemenu'

import RolesMngmt from './Roles'
import UsersMngmt from './Users'
import { getItem } from 'utils/tokenStore'
import CompanyMngmt from './Company'
import CaseTypeMngmt from './CaseType'
import CaseReport from './CaseReport'
// import CookieCategory from './CookieBanner/CookieCategory'
// import CookieContent from './CookieBanner/CookieContent'
import LanguageTranslation from './LanguageTranslation.js'
import FormBuilder from './FormBuilder'
import FormLang from './FormLang'

import PageFooter from 'components/Footer';
import LawyerCase from './LawyerCase'

import { getProfile } from 'actions/profile'
import { getDictionaryRole } from 'actions/dictionary'

const PrefixURL = '/dashboard/admin'

const meta = {
  meta: {
    name: {
      robots: 'noindex'
    }
  }
};

class Dashboard extends Component {

  componentDidMount = () => {
    if (!getItem('BPK_ACCESS_TOKEN')) {
      this.props.history.push('/admin/login');
    } else {
      this.props.getDictionaryRole()

      getItem('BPK_USER_TYPE') !== 'case' && this.props.getProfile()
    }
  }

  componentDidUpdate = pp => {
    if (this.props.location.pathname !== pp.location.pathname) {
      this.props.location.pathname.indexOf('admin') > -1 &&
        getItem('BPK_USER_TYPE') === 'case' &&
        this.props.history.push('/dashboard/case-mngmt')
    }
  }

  render = () => {
    return (
      <DocumentMeta {...meta}>
        <TemplateContainerMain>
          <TemplateHeader
            onClickToggleProfileModal={() => { }}
            history={this.props.history}
            userType={getItem('BPK_USER_TYPE') === 'non-case' ? 'admin' : 'case'}
            user={{
              name: 'Test user',
              email: 'testuser@gmail.com'
            }} />
          <div className="app-main">
            {
              getItem('BPK_USER_TYPE') === 'non-case' && (
                <TemplateSidemenu
                  userPermissions={this.props.userPermissions}
                  user={this.props.user}
                  userRole={this.props.userRole} />
              )
            }
            <div className="app-main__outer">
              <div className="app-main__inner">
                <Route
                  path={`/dashboard/case-mngmt`}
                  render={props => (
                    <LawyerCase {...props} />
                  )}
                />
                <Route
                  path={`${PrefixURL}/roles`}
                  render={props => (
                    <RolesMngmt {...props} />
                  )} />
                <Route
                  path={`${PrefixURL}/users`}
                  render={props => (
                    <UsersMngmt {...props} />
                  )} />
                <Route
                  path={`${PrefixURL}/companies`}
                  render={props => (
                    <CompanyMngmt {...props} />
                  )} />
                <Route
                  path={`${PrefixURL}/case-type`}
                  render={props => (
                    <CaseTypeMngmt {...props} />
                  )} />
                <Route
                  path={`${PrefixURL}/form-builder`}
                  render={
                    props => (
                      <FormBuilder {...props} />
                    )
                  }
                />
                <Route
                  path={`${PrefixURL}/form-lang`}
                  render={
                    props => (
                      <FormLang {...props} />
                    )
                  }
                />
                <Route
                  path={`${PrefixURL}/case-report`}
                  render={
                    props => (
                      <CaseReport {...props} />
                    )
                  }
                />
                {/* <Route 
                  path={ `${ PrefixURL }/cookie-categories` } 
                  render={ props => (
                    <CookieCategory { ...props } />
                  )} /> */}
                {/* <Route
                  path={ `${ PrefixURL }/cookie-content` }
                  render={
                    props => (
                      <CookieContent { ... props }/>
                    )
                  } 
                /> */}
                {/* <Route
                  path={ `${ PrefixURL }/translation` }
                  render={
                    props => (
                      <LanguageTranslation { ... props }/>
                    )
                  } 
                /> */}
                <PageFooter />
              </div>
            </div>
          </div>
          <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        </TemplateContainerMain>
      </DocumentMeta>
    )
  }
}

const mapStateToProps = state => ({ data: state })
export default connect(mapStateToProps, {
  getDictionaryRole,
  getProfile,
  withRouter
})(Dashboard)