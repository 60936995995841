import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  Row
} from 'reactstrap'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import TransComponent from 'components/Trans'
import ReactSelect from 'react-select'

import { queryHandler } from 'utils/queryHandler'

const SearchForm = ({
  per_page,
  searchParams,
  onChangeHOCState,
  getListAPI,
  renderCustomButton
}) => {
  return (
    <Card className="main-card mb-3">
      <CardHeader style={{ textTransform: 'capital' }}>
        <TransComponent i18nKey="general.search" />
      </CardHeader>
      <CardBody>
        <Form onSubmit={e => e.preventDefault()}>
          <Row>
            {
              searchParams.map((item, index) => {
                return (
                  <Col key={index} md={item.col || 12} xs={12}>
                    <FormGroup key={item.value}>
                      <Label className={item.className || ''}>
                        {
                          item.transKey && (
                            <TransComponent i18nKey={item.transKey} />
                          )
                        }
                        {
                          !item.transKey && (
                            `${item.label}`
                          )
                        }
                      </Label>
                      {
                        item.type === 'select' && (
                          <>
                            <ReactSelect
                              className={item.className}
                              options={ item.options.map(data => ({
                                ... data,
                                label: data.name,
                                value: data.id,
                              })) }
                              onChange={ val => {
                                let tmp = _.cloneDeep(searchParams)

                                tmp[index]['param'] = val?.id
                                onChangeHOCState('searchParams', tmp)
                              }}
                              value={
                                _.find(item.options, option => option.id === item.param) 
                                  ? [_.find(item.options, option => option.id === item.param)]
                                  : []
                                } 
                            />
                            {
                              console.log(_.find(item.options, option => option.id === item.param))
                            }
                          </>
                        )
                      }
                      {
                        item.type === 'text' && (
                          <Input
                            type={'text'}
                            value={item.param}
                            onChange={e => {
                              let tmp = _.cloneDeep(searchParams)
                              tmp[index]['param'] = e.target.value
                              onChangeHOCState('searchParams', tmp)
                            }} />
                        )
                      }
                      {
                        item.type === 'date' && (
                          <DatePicker
                            className={'w-100 form-control'}
                            dateFormat="dd-MM-yyyy"
                            selected={item.param}
                            onChange={val => {
                              let tmp = _.cloneDeep(searchParams)
                              tmp[index]['param'] = val
                              onChangeHOCState('searchParams', tmp)
                            }}
                          />
                        )
                      }
                    </FormGroup>
                  </Col>
                )
              })
            }
          </Row>
          <div className="d-flex">
            <Button
              color="primary"
              style={{ marginLeft: 'auto' }}
              disabled={searchParams?.[2] ? !searchParams?.[2]?.param : false}
              onClick={() => {
                getListAPI()
              }}>{<TransComponent i18nKey="general.search" />}</Button>
            {
              renderCustomButton &&
              renderCustomButton(queryHandler(searchParams, 0, per_page))
            }
            <Button
              color="danger"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                let tmp = []
                let tmpSearchParams = _.cloneDeep(searchParams)
                tmpSearchParams.map(item => {
                  tmp.push({
                    ...item,
                    param: item.type === 'date' 
                      ? item.transKey !== 'general.from'
                        ? new Date()
                        : new Date(new Date().setMonth(new Date().getMonth() - 1))
                      : ''
                  })
                })
                onChangeHOCState('searchParams', tmp)
                onChangeHOCState('selectedReport', [])
              }}>{<TransComponent i18nKey="general.reset" />}</Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default SearchForm